import React from 'react';
import styles from './Button.module.scss';


const Button = (props) => {
  return (
    <button className={styles.button} {...props} onClick={props.onClick}>{props.name}</button>
  )
}

export default Button;