import React from 'react'
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { Navigate } from 'react-router-dom';
import Spinner from '../ui/Spinner';

function PrivateRoute({ component: Component, auth: { isAuthenticated, loading } }) {
    if (isAuthenticated) return <Component />
    if (!isAuthenticated) return <Navigate to='/agency-login' />
    if (loading) return <h1>Loading</h1>;
}

PrivateRoute.prototype = {
    auth: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    auth: state.auth
})

export default connect(mapStateToProps)(PrivateRoute);