import { SET_FORM_DATA, SET_BOOKING_HISTORY } from './../actions/types';

const initialState = {
    formdata: {},
    bookingHistory: JSON.parse(localStorage.getItem("booking history"))
}

const formReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_FORM_DATA:
            localStorage.setItem("booking details", JSON.stringify(action.payload))
            
            return {
                ...state,
                formdata: action.payload
            }
        case SET_BOOKING_HISTORY:
            localStorage.setItem("booking history", JSON.stringify(action.payload))
            return {
                ...state,
                bookingHistory: action.payload
            }
        default:
            return state;
    }
}

export default formReducer;