import React, { Fragment, useState } from 'react';
import "./Dashboard.css";
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Header from '../components/Header';
import { SideNav } from '../components/SideNav';
import Button from '../components/ui/Button';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';

const AgencyHome = ({ auth: { isAuthenticated } }) => {

  const navigate = useNavigate();

  if (!isAuthenticated) {
    navigate('/agency-login')
  }

  return (
    <Fragment>
      <Header />
      <SideNav />
      <div className='app-content'>
        <div id='dashboard'>
          <div className='row m-0 p-0'>
            <div className='col-md-6'>
              <div className='dashboard-card home-card p-4 p-xl-5'>
                <div className='d-flex flex-column justify-content-center align-items-center'>
                  <h1 className='heading text-center'>Agency Booking</h1>
                  {/* <p className='text-center'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ac lorem at nisi ornare pharetra eu in urna. Duis sem.</p> */}
                  <br></br>
                  <div className='d-flex '>
                    <Button name="Book Now" type="button" onClick={() => navigate(`/agency-booking/${uuidv4()}`)}></Button>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='dashboard-card home-card p-4 p-xl-5'>
                <div className='d-flex flex-column justify-content-center align-items-center'>
                  <h1 className='heading text-center'>Agency Booking History</h1>
                  {/* <p className='text-center'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ac lorem at nisi ornare pharetra eu in urna. Duis sem.</p> */}
                  <br></br>
                  <div className='d-flex '>
                    <Button name="View Booking History" type="button" onClick={() => navigate('/agency-booking-details')}></Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </Fragment>
  )
}

AgencyHome.prototype = {
  auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps)(AgencyHome)

{/* <div className='row m-0'>
          <div className='col-md-3'>
            <div className='dashboard-card' onClick={() => onClickCard('/booking-details')}>
              <h1 className='dashboard-card_heading'>Booking Details</h1>
            </div>
          </div>
          <div className='col-md-3'>
            <div className='dashboard-card' onClick={() => onClickCard('/enquiry-details')}>
              <h1 className='dashboard-card_heading'>Enquiry Details</h1>
            </div>
          </div>
          <div className='col-md-3'>
            <div className='dashboard-card' onClick={() => onClickCard('/agency-details')}>
              <h1 className='dashboard-card_heading'>Agency Details</h1>
            </div>
          </div>
          <div className='col-md-3'>
            
          </div>
        </div> */}