export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";

export const LOGOUT = "LOGOUT";

export const SET_FORM_DATA = "SET_FORM_DATA";
export const SET_BOOKING_HISTORY = "SET_BOOKING_HISTORY";

export const PROFILE_UPDATED = "PROFILE_UPDATED";
export const PROFILE_UPDATED_FAIL = "PROFILE_UPDATED_FAIL";

export const BOOKING_SUCCESS = "BOOKING_SUCCESS";
export const BOOKING_FAIL = "BOOKING_FAIL";

export const GET_BOOKING_DETAILS = "GET_BOOKING_DETAILS";
export const GET_BOOKING_DETAILS_ERROR = "GET_BOOKING_DETAILS_ERROR";

export const GET_BOOKING_HISTORY = "GET_BOOKING_HISTORY";
export const BOOKING_HISTORY_ERROR = "BOOKING_HISTORY_ERROR";