import { PropTypes } from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getBookingHistory } from '../actions/booking';
import Header from '../components/Header';
import { SideNav } from '../components/SideNav';
import { Footer } from '../components/layout/Footer';
import TotalSales from "../total-sales.json";
import "./Dashboard.css";


const Dashboard = ({ auth: { isAuthenticated }, booking: { bookingHistory }, getBookingHistory }) => {

  useEffect(() => {
    getBookingHistory()
  }, [getBookingHistory])

  const [data, setData] = useState([...TotalSales].reverse());

  const navigate = useNavigate();

  const onClickCard = (cardId) => {
    navigate(cardId);
    localStorage.removeItem('activeMenu');
    // Save the active menu to localStorage
    localStorage.setItem('activeMenu', cardId);
  }

  const config = {
    data,
    padding: 'auto',
    xField: 'Date',
    yField: 'scales',
    xAxis: {
      tickCount: 5,
    },
    slider: {
      start: 0,
      end: 1,
    },
  };

  if (!isAuthenticated) {
    navigate('/agency-login');
  }

  let totalSales = 0;
  bookingHistory.map(bookings => {
    totalSales += Number(bookings.total_amount);
  })

  return (
    <Fragment>
      <Header />
      <SideNav />
      <div className='app-content'>
        <div id='dashboard'>
          <div className='row m-0'>
            <div className='col-md-6'>
              <div className='dashboard-card row p-3'>
                <div className='col-sm-7 d-flex flex-column justify-content-center align-items-start'>
                  <p className='dashboard-card_sub-heading'>Travellers</p>
                  <h1 className='dashboard-card_heading'>Total Bookings</h1>
                </div>
                <div className='col-sm-5 d-flex justify-content-end align-items-center'>
                  <h1 className='dashboard-card_number text-end'>{bookingHistory?.length}</h1>
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='dashboard-card row p-3'>
                <div className='col-sm-7 d-flex flex-column justify-content-center align-items-start'>
                  <p className='dashboard-card_sub-heading'>Travellers</p>
                  <h1 className='dashboard-card_heading'>Total Sales</h1>
                </div>
                <div className='col-sm-5 d-flex justify-content-end align-items-center'>
                  <h1 className='dashboard-card_number text-end'>{totalSales}</h1>
                </div>
              </div>
            </div>
            {/* <div className='col-md-6'>
              <div className='dashboard-card row p-3'>
                <div className='col-sm-7 d-flex flex-column justify-content-center align-items-start'>
                  <p className='dashboard-card_sub-heading'>This Year</p>
                  <h1 className='dashboard-card_heading'>Total Sales</h1>
                </div>
                <div className='col-sm-5 d-flex justify-content-end align-items-center'>
                  <h1 className='dashboard-card_number text-end'>50</h1>
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='dashboard-card row p-3'>
                <div className='col-sm-7 d-flex flex-column justify-content-center align-items-start'>
                  <p className='dashboard-card_sub-heading'>This Year</p>
                  <h1 className='dashboard-card_heading'>Travellers Booked</h1>
                </div>
                <div className='col-sm-5 d-flex justify-content-end align-items-center'>
                  <h1 className='dashboard-card_number text-end'>50</h1>
                </div>
              </div>
            </div> */}
          </div>
          <br></br>
          {/* <div id='chart-sections'>
            <div className='row m-0'>
              <div className='col-md-6 p-3'>
                <div className='chart-card'>
                  <div className='chart'>
                    <Line {...config} />
                  </div>
                  <h1 className='dashboard-card_heading text-center'>User Booking History</h1>
                </div>
              </div>
              <div className='col-md-6 p-3'>
                <div className='chart-card'>
                  <div className='chart'>
                    <Line {...config} />
                  </div>
                  <h1 className='dashboard-card_heading text-center'>Travel Agency Booking History</h1>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </Fragment>
  )
}

Dashboard.prototype = {
  auth: PropTypes.object.isRequired,
  booking: PropTypes.array.isRequired,
  getBookingHistory: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    booking: state.booking,
  }
}

export default connect(mapStateToProps, { getBookingHistory })(Dashboard)
