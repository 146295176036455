import React, { Fragment } from 'react';
import Header from '../components/Header';
import { SideNav } from '../components/SideNav';

const AgentRefundPolicy = () => {

  return (
    <Fragment>
      <Header />
      <SideNav />
      <div className='app-content'>
        <section id='about-section'>
          <h1 className='heading text-center mb-4'>Refund Policy</h1>
          <p className='text-center'>Cancellation & Refund Policy: Reschedule might be subject to availability. The full payment confirms your reservation, with details provided in the confirmation email. Note: The initial deposit is non-refundable, and no-shows or cancellations before 24 hours of Sea Walk Time get deducts Rs.1000/ as a cancellation fee. We encourage rescheduling, possible up to 5 days before the original booking date. For assistance, contact our customer service team.</p>
        </section>
      </div>
    </Fragment>
  )
}

export default AgentRefundPolicy