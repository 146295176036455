import React, { Fragment, useEffect } from 'react';
import { DatePicker, Space, Table, Tag } from 'antd';
import Header from '../components/Header';
import { SideNav } from '../components/SideNav';
import { PDFDownloadLink, PDFViewer, usePDF } from '@react-pdf/renderer';
import MyDocument from '../components/ui/DownloadPDF';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { getBookingHistory } from '../actions/booking';
import { Navigate } from 'react-router-dom';
import Spinner from '../components/ui/Spinner';

const AgencyDetails = ({ getBookingHistory, booking: { bookingHistory, loading }, auth: { isAuthenticated, user } }) => {

  useEffect(() => {
    getBookingHistory()
  }, [getBookingHistory])

  if (!isAuthenticated) {
    return <Navigate to='/' />
  }

  const columns = [
    {
      title: 'Booking ID',
      dataIndex: 'booking_id',
      key: 'booking_id',
      render: (text, bookingDetails) => (
        <p className='m-0'>{text}</p>
      ),
    },
    {
      title: 'Booked Date',
      dataIndex: 'date_time',
      key: 'date_time',
      render: (text, bookingDetails) => (
        <p className='m-0'>{new Date(bookingDetails.date_time).toLocaleDateString()}</p>
      ),
    },
    {
      title: 'Sea Walk Date',
      dataIndex: 'seawalk_date',
      key: 'seawalk_date',
    },
    {
      title: 'No.Of Travellers',
      dataIndex: 'no_of_tourist',
      key: 'no_of_tourist',
      render: (text, bookingDetails) => (
        <p className='m-0'>{bookingDetails.tourist_details.length}</p>
      ),
    },
    {
      title: 'Payment Status',
      key: 'status',
      dataIndex: 'status',
      render: (_, { status }) => {
        let color;
        if (status === 'not paid') {
          color = 'volcano';
        }
        if (status === 'paid') {
          color = 'green';
        }
        if (status === 'pending') {
          color = 'geekblue';
        }
        return (
          <Tag color={color} key={status}>
            {status.toUpperCase()}
          </Tag>
        )
      }
    },
    {
      title: 'PDF',
      key: 'pdf',
      render: (_, bookingDetails) => (
        <Space size="middle">
          <PDFDownloadLink className="pt-3 download-bottom-btn" document={<MyDocument id={bookingDetails.id} bookingDetails={bookingDetails} user={user} />} fileName={`SeaWalk in Andaman Ticket.pdf`}>
            {({ blob, url, loading, error }) =>
              loading ? 'Download PDF' : <button>Download PDF <img style={{ width: "15px" }} className='ms-2' src='/images/thankyou/download-white.png' alt='download'></img></button>
            }
          </PDFDownloadLink>
        </Space>
      ),
    },
  ];


  return loading || bookingHistory == null ? <Spinner /> : <Fragment>
    <Header />
    <SideNav />
    <div className='app-content'>
      <div id='booking-details'>
        <div className='p-2'>
          <h1 className='title'>Agency Booking Details</h1>
          <Table
            columns={columns}
            dataSource={bookingHistory && bookingHistory}
            scroll={{ x: 1300 }}
          />
        </div>
      </div>
    </div>
  </Fragment>
}

AgencyDetails.prototype = {
  getBookingHistory: PropTypes.func.isRequired,
  booking: PropTypes.array.isRequired,
  auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
  return {
    booking: state.booking,
    auth: state.auth
  }
}

export default connect(mapStateToProps, { getBookingHistory })(AgencyDetails);