import React, { Fragment, useState } from 'react';
import "./Header.css";
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { logout } from './../actions/auth';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Button, Drawer, message } from 'antd';
import "./SideNav.css"

const Header = ({ logout, auth: { isAuthenticated, loading } }) => {

  const navigate = useNavigate()

  const [loadUser, setLoadUser] = useState(false);
  const onLogout = () => {
    logout()
    navigate('/agency-login')
  }

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const [activeMenu, setActiveMenu] = useState(null);

  const onClickMenu = (menuId) => {
    setActiveMenu(menuId);
  }


  return (
    <Fragment>
      <header>
        <div className='header'>
          <div className='d-flex align-items-center d-lg-none'>
            <a href="/" >
              <img className="logo" src='/images/thankyou/sea-gold-logo.jpg' alt="logo"></img>
            </a>
            <img className="d-block d-lg-none hamburger-menu" src='/images/hamburger-menu.png' alt="logo" onClick={showDrawer}></img>
            <Drawer title="SEA GOLD SEA WALK" placement="left" onClose={onClose} open={open}>
              <ul className='p-0 h-100 d-flex flex-column justify-content-between'>
                <div>
                  <li><NavLink className={activeMenu === 'Dashboard' ? 'menu-link active' : 'menu-link'} to='/agency-dashboard' onClick={() => onClickMenu('Dashboard')}>Dashboard</NavLink></li>
                  {/* <hr></hr> */}
                  <div className='menu-group'>
                    <h6>Travel Agency</h6>
                    <li><NavLink className={activeMenu === 'agency-booking-details' ? 'menu-link active' : 'menu-link'} to='/agency-booking-details' onClick={() => onClickMenu('agency-booking-detailst')}>Booking Details</NavLink></li>
                    <li><NavLink className={activeMenu === '' ? 'menu-link active' : 'menu-link'} to='/' onClick={() => onClickMenu('')}>Book Sea Walk</NavLink></li>
                  </div>
                </div>

                <div className='bottom-menu'>
                  <li className="mb-1"><NavLink to='/terms-and-conditions' onClick={() => onClickMenu('')}>Terms & Conditions</NavLink></li>
                  <li className="mb-1"><NavLink to='/privacy-policy' onClick={() => onClickMenu('')}>Privacy Policy</NavLink></li>
                  <li className="mb-1"><NavLink to='/agent-refund-policy' onClick={() => onClickMenu('')}>Refund Policy</NavLink></li>
                </div>
              </ul>
            </Drawer>
          </div>
          <div></div>
          <div className='d-flex justify-content-end align-items-center'>
            <Link to="/" className="nav-link active-menu d-none d-md-block">
              Book Sea Walk
            </Link>

            <div className="dropdown nav-link">
              <span><img className="user" src='/images/user.png' alt="logo"></img></span>
              {
                !loading && isAuthenticated ?
                  <div className="dropdown-content">
                    <Link to="/agency-profile">Profile</Link>
                    <Link to="/agency-booking-details">Booking History</Link>
                    <Link to="#!" onClick={onLogout}>Logout</Link>
                  </div> : ""
              }
            </div>
          </div>
        </div>
      </header>
    </Fragment>
  )
}

Header.prototype = {
  logout: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  auth: state.auth
})

export default connect(mapStateToProps, { logout })(Header);