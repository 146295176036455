import { DatePicker, Input, InputNumber, Select } from 'antd';
import PropTypes from "prop-types";
import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../components/Header';
import { SideNav } from '../components/SideNav';
import Button from '../components/ui/Button';
import { updateUserProfile, loadUser } from './../actions/auth';
import { setBookingHistory, setFormdata } from './../actions/formActions';
import "./Booking.scss";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { makeBooking } from './../actions/booking';

const { Option } = Select;

const AgencyBooking = ({ makeBooking, setFormdata, auth: { user, isAuthenticated }, updateUserProfile, guestUser }) => {

    const id = useParams();

    const navigate = useNavigate();

    // const data = JSON.parse(localStorage.getItem("booking form details")) || JSON.parse(localStorage.getItem("booking details"))

    // const findBookingById = (id) => {
    //     if (data.id === id) {
    //         return data;
    //     }
    // };
    const [disabled, setDisabled] = useState(isAuthenticated);
    const loggedUser = isAuthenticated ? user : {};

    // const bookingformdata = findBookingById(id.agencyBooking);

    const [formData, setFormData] = useState({
        date: '',
        slot: '',
        travelers: 0,
    });

    const [agencyErrors, setAgencyErrors] = useState({});

    const handleChange = (field, value) => {
        setFormData({
            ...formData,
            [field]: value,
        });
    };

    // disable past date in select date dropdown
    dayjs.extend(customParseFormat);
    const disabledDate = (current) => {
        const isTimeAfterThree = dayjs().hour() >= 15
        return isTimeAfterThree ? current < dayjs().endOf('day') : current < dayjs().startOf('day');
    };
    // generate time slot
    const currentHour = dayjs().hour();
    const generateTimeSlots = () => {

        const availableSlots = [
            "09.00 AM",
            "10.00 AM",
            "11.00 AM",
            "12.00 PM",
            "01.00 PM",
            "02.00 PM",
            "03.00 PM"
        ];
        const isToday = new Date(formData.date).getDate() === dayjs().date();
        let disabledSlots = [];
        if (isToday) {
            if (currentHour < 9) {
                return availableSlots.slice(0, 7);
            }
            if (currentHour < 10 && currentHour >= 9) {
                const disabledSlots = ["09.00 AM"]
                return availableSlots.map(slot => {
                    const isDisabled = disabledSlots.includes(slot);
                    return (
                        <Option key={slot} value={slot} disabled={isDisabled} style={{ backgroundColor: isDisabled ? "#D8D8D8" : "", color: isDisabled ? "gainsboro" : "" }}>
                            {slot}
                        </Option>
                    );
                });
            }
            if (currentHour < 11 && currentHour >= 10) {
                const disabledSlots = ["09.00 AM", "10.00 AM"]
                return availableSlots.map(slot => {
                    const isDisabled = disabledSlots.includes(slot);
                    return (
                        <Option key={slot} value={slot} disabled={isDisabled} style={{ backgroundColor: isDisabled ? "#D8D8D8" : "", color: isDisabled ? "gainsboro" : "" }}>
                            {slot}
                        </Option>
                    );
                });
            }
            if (currentHour < 12 && currentHour >= 11) {
                const disabledSlots = ["09.00 AM", "10.00 AM", "11.00 AM"]
                return availableSlots.map(slot => {
                    const isDisabled = disabledSlots.includes(slot);
                    return (
                        <Option key={slot} value={slot} disabled={isDisabled} style={{ backgroundColor: isDisabled ? "#D8D8D8" : "", color: isDisabled ? "gainsboro" : "" }}>
                            {slot}
                        </Option>
                    );
                });
            }
            if (currentHour < 13 && currentHour >= 12) {
                const disabledSlots = ["09.00 AM", "10.00 AM", "11.00 AM", "12.00 PM"]
                return availableSlots.map(slot => {
                    const isDisabled = disabledSlots.includes(slot);
                    return (
                        <Option key={slot} value={slot} disabled={isDisabled} style={{ backgroundColor: isDisabled ? "#D8D8D8" : "", color: isDisabled ? "gainsboro" : "" }}>
                            {slot}
                        </Option>
                    );
                });
            }
            if (currentHour < 14 && currentHour >= 13) {
                const disabledSlots = ["09.00 AM", "10.00 AM", "11.00 AM", "12.00 PM", "01.00 PM",]
                return availableSlots.map(slot => {
                    const isDisabled = disabledSlots.includes(slot);
                    return (
                        <Option key={slot} value={slot} disabled={isDisabled} style={{ backgroundColor: isDisabled ? "#D8D8D8" : "", color: isDisabled ? "gainsboro" : "" }}>
                            {slot}
                        </Option>
                    );
                });
            }
            if (currentHour < 15 && currentHour >= 14) {
                const disabledSlots = ["09.00 AM", "10.00 AM", "11.00 AM", "12.00 PM", "01.00 PM", "02.00 PM"]
                return availableSlots.map(slot => {
                    const isDisabled = disabledSlots.includes(slot);
                    return (
                        <Option key={slot} value={slot} disabled={isDisabled} style={{ backgroundColor: isDisabled ? "#D8D8D8" : "", color: isDisabled ? "gainsboro" : "" }}>
                            {slot}
                        </Option>
                    );
                });
            }
        }

        return availableSlots.map(slot => {
            const isDisabled = disabledSlots.includes(slot);
            return (
                <Option key={slot} value={slot} disabled={isDisabled} style={{ backgroundColor: isDisabled ? "#D8D8D8" : "", color: isDisabled ? "gainsboro" : "" }}>
                    {slot}
                </Option>
            );
        });
    };

    const [bookingDetails, setBookingDetails] = useState({
        id: id.agencyBooking,
        travelersDetails: [],
        agencyDetails: {
            agencyName: loggedUser.user_name,
            agencyEmailId: loggedUser.email,
            agencyPhoneNumber: loggedUser.phone_number,
            agencyCity: loggedUser.city,
            agencyCompanyName: loggedUser.agent_company_name
        }
    });

    const [errors, setErrors] = useState();

    // handler for user phone number input
    const handleUserPhoneNumber = (value, name) => {
        setBookingDetails({
            ...bookingDetails,
            userDetails: {
                ...bookingDetails.userDetails,
                userPhoneNumber: value,
            },
        });
    }

    // handler for user inputs
    const handleUserInputChange = (event) => {
        const { name, value } = event.target;

        setBookingDetails({
            ...bookingDetails,
            userDetails: {
                ...bookingDetails.userDetails,
                [name]: value,
            },
        });
    };

    // Function to handle traveler name input changes
    const handleTravelerName = (event, index) => {
        const { name, value } = event.target;
        const updatedTravelersDetails = [...bookingDetails.travelersDetails];
        if (!updatedTravelersDetails[index]) {
            updatedTravelersDetails[index] = {};
        }
        updatedTravelersDetails[index][name] = value;

        setBookingDetails({
            ...bookingDetails,
            travelersDetails: updatedTravelersDetails,
        });
    }

    // Function to handle traveler gender and age input changes
    const handleTravelerInputChange = (value, index, inputName) => {
        const updatedTravelersDetails = [...bookingDetails.travelersDetails];
        if (!updatedTravelersDetails[index]) {
            updatedTravelersDetails[index] = {};
        }
        updatedTravelersDetails[index][inputName] = value;

        setBookingDetails({
            ...bookingDetails,
            travelersDetails: updatedTravelersDetails,
        });
    };


    // display no of rows for travelers details input
    const travelerRows = Array.from({ length: formData.travelers }, (_, index) => (
        <div key={index} className='row m-0'>
            <div className='col-1 p-0'>
                <div className='form-input'>
                    <p className='form-Sno m-0'>{index + 1}.</p>
                </div>
            </div>
            <div className='col-11 col-md-5 p-0'>
                <div className='form-input'>
                    <Input name={`travelerName`} placeholder="Name *" autoComplete="none" onChange={(event) => handleTravelerName(event, index)} required />
                    {
                        errors && errors.map((error, i) => {
                            if (i === index) {
                                return <>
                                    {error.name && <p className='error'>* {error.name}</p>}
                                </>
                            }
                        })
                    }
                </div>
            </div>
            <div className='col-6 col-md-3 p-0'>
                <div className='form-input'>
                    <Select
                        name={`travelerGender`}
                        placeholder="Gender *"
                        style={{ width: '100%' }}
                        onChange={(value) => handleTravelerInputChange(value, index, 'travelerGender')}
                        options={[
                            { value: 'Male', label: 'Male' },
                            { value: 'Female', label: 'Female' },
                            { value: 'Other', label: 'Other' },
                        ]}
                    />
                    {
                        errors && errors.map((error, i) => {
                            if (i === index) {
                                return <>
                                    {error.gender && <p className='error'>* {error.gender}</p>}
                                </>
                            }
                        })
                    }
                </div>
            </div>
            <div className='col-6 col-md-3 p-0'>
                <div className='form-input'>
                    <InputNumber
                        name={`travelerAge`}
                        min={14}
                        max={50}
                        style={{ width: "100%" }}
                        placeholder="Age *"
                        autoComplete="none"
                        type='number'
                        onChange={(value) => handleTravelerInputChange(value, index, 'travelerAge')} />
                    {
                        errors && errors.map((error, i) => {
                            if (i === index) {
                                return <>
                                    {error.age && <p className='error'>* {error.age}</p>}
                                </>
                            }
                        })
                    }
                </div>
            </div>
        </div>
    ));

    const bookingHistory = JSON.parse(localStorage.getItem("agency booking history"))
    const agencyList = JSON.parse(localStorage.getItem("Agency List"));
    const objectIndex = agencyList && agencyList.findIndex(obj => obj.id === id.agencyBooking);

    function generateBookingID() {
        // Replace this with a unique identifier
        const uniqueString = "SEAGOLD";
        const date = `${new Date().getFullYear()}${new Date().getMonth() + 1}${new Date().getDate()}`
        const time = `${new Date().getHours()}${new Date().getMinutes()}${new Date().getSeconds()}${new Date().getMilliseconds()}`
        const randomDigits = Math.floor(Math.random() * 1000).toString().padStart(5, '0'); // Generate random 3-digit number and pad with zeros
        const bookingID = `${uniqueString}${date}${time}`;
        return bookingID;
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        const travelerDetailsArray = bookingDetails.travelersDetails.map((traveler, index) => {
            // Create a new object with the traveler's details
            const travelerDetails = {
                name: !traveler.travelerName ? 'Name is required' : null,
                gender: !traveler.travelerGender ? 'Gender is required' : null,
                age: !traveler.travelerAge ? 'Age is required' : null,
            };
            return travelerDetails;
        });
        if (bookingDetails.travelersDetails.length === 0 && formData.travelers) {
            const travelerDetails = {
                name: 'Name is required',
                gender: 'Gender is required',
                age: 'Age is required',
            };
            setErrors([{ ...travelerDetails }]);
            travelerDetailsArray = [{ ...travelerDetails }]
        }
        // Check if all values in the array of objects are null
        const allValuesAreNull = travelerDetailsArray.every((obj) =>
            Object.values(obj).every((value) => value === null)
        );

        const validationErrors = {};
        // Perform your validation checks here
        if (!formData.date) {
            validationErrors.date = 'Date is required';
        }
        if (!formData.slot) {
            validationErrors.slot = 'Slot is required';
        }
        if (!formData.travelers) {
            validationErrors.travelers = 'Number of travelers is required';
        }

        // if all error values are null then submit form else display error
        if (allValuesAreNull && Object.keys(validationErrors).length === 0) {

            const formDataWithId = {
                ...bookingDetails,
                bookingID: generateBookingID(),
                bookedOn: new Date(),
                seawalkdate: new Date(formData.date).toLocaleDateString(),
                bookedSlot: formData.slot,
                travelers: formData.travelers,
                amount: formData.travelers * 3100,
                gst: Math.round((formData?.travelers * 3100) * 5 / (100 + 5)),
                totalAmount: formData.travelers * 3100,
            };
            setFormdata(formDataWithId);

            const { bookingID, seawalkdate, bookedSlot, travelers, totalAmount, travelersDetails } = formDataWithId;
            makeBooking({ bookingID, seawalkdate, bookedSlot, travelers, totalAmount, travelersDetails });
            // navigate('/thankyou/' + bookingID);
            setErrors([]);
            setAgencyErrors({})
        } else {
            setErrors(travelerDetailsArray);
            setAgencyErrors(validationErrors);
            if (bookingDetails.travelersDetails.length === 0) {
                const travelerDetails = {
                    name: 'Name is required',
                    gender: 'Gender is required',
                    age: 'Age is required',
                };
                setErrors([{ ...travelerDetails }]);
            } else {
                setErrors(travelerDetailsArray)
            }
        }

    };

    return (
        <Fragment>
            <Header />
            <SideNav />
            <div className='app-content'>
                <form onSubmit={handleSubmit}>
                    <div style={{ position: "relative" }} className='pb-5'>
                        <div className='row m-0'>
                            <div className='col-xl-8 p-4 pt-2'>
                                <h1 className='details_title'>Agency Details</h1>
                                <div id='user-details' className='p-2 user-details'>
                                    <div className='w-100'>
                                        <div className='row m-0 p-0'>
                                            <div className='col-md-4 p-0'>
                                                <div className='form-input'>
                                                    <label>Name: <span style={{ color: "red" }}>*</span></label>
                                                    <Input name='agencyName' placeholder="Name" autoComplete="none" disabled={disabled} value={bookingDetails.agencyDetails.agencyName} onChange={handleUserInputChange} required />
                                                </div>
                                            </div>
                                            <div className='col-md-4 p-0'>
                                                <div className='form-input'>
                                                    <label>Agency Name: <span style={{ color: "red" }}>*</span></label>
                                                    <Input name='agencyCompanyName' placeholder="Agency Company Name" autoComplete="none" disabled={disabled} value={bookingDetails.agencyDetails.agencyCompanyName} onChange={handleUserInputChange} required />
                                                </div>
                                            </div>
                                            <div className='col-md-4 p-0'>
                                                <div className='form-input'>
                                                    <label>Email ID: <span style={{ color: "red" }}>*</span></label>
                                                    <Input name='agencyEmailId' placeholder="Email Id" autoComplete="none" type='email' disabled={disabled} value={bookingDetails.agencyDetails.agencyEmailId} onChange={handleUserInputChange} required />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row m-0 p-0'>
                                            <div className='col-md-4 p-0'>
                                                <div className='form-input'>
                                                    <label>Phone Number: <span style={{ color: "red" }}>*</span></label>
                                                    <InputNumber name='agencyPhoneNumber' min={1} style={{ width: "100%" }} type='number' placeholder="Phone Number" autoComplete="none" disabled={disabled} value={bookingDetails.agencyDetails.agencyPhoneNumber} onChange={handleUserPhoneNumber} required />
                                                </div>
                                            </div>
                                            <div className='col-md-4 p-0'>
                                                <div className='form-input'>
                                                    <label>City: <span style={{ color: "red" }}>*</span></label>
                                                    <Input name='agencyCity' placeholder="City" autoComplete="none" disabled={disabled} value={bookingDetails.agencyDetails.agencyCity} onChange={handleUserInputChange} required />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className='details_title'>Seawalk Details</h1>
                                <div id="user-details" className="p-2 user-details row mb-4 m-0 m-md-0 d-flex">
                                    <div className="col-md-4 p-2 p-md-3 form-date">
                                        <label>Select Sea Walk Date: <span style={{ color: "red" }}>*</span></label>
                                        <DatePicker
                                            placeholder="Select Date"
                                            format={'DD/MM/YYYY'}
                                            placement={'bottomLeft'}
                                            style={{ width: '100%' }}
                                            value={formData.date}
                                            disabledDate={disabledDate}
                                            onChange={(value) => handleChange('date', value)}
                                        />
                                        {agencyErrors.date && <p className="error">* {agencyErrors.date}</p>}
                                    </div>
                                    <div className="col-md-4 pt-0 pt-md-3 p-2 p-md-3 form-slot">
                                        <label>Choose Slot (Time): <span style={{ color: "red" }}>*</span></label>
                                        <Select
                                            placeholder="Choose Slot (Time)"
                                            style={{ width: "100%" }}
                                            onChange={value => handleChange("slot", value)}
                                            disabledDate={disabledDate}
                                        >
                                            {generateTimeSlots()}
                                        </Select>
                                        {agencyErrors.slot && <p className="error">* {agencyErrors.slot}</p>}
                                    </div>
                                    <div className="col-md-4 pt-0 pt-md-3 p-2 p-md-3 form-travellers">
                                        <label>No.of Travellers: <span style={{ color: "red" }}>*</span></label>
                                        <Select
                                            placeholder="No.of Travellers"
                                            style={{ width: '100%' }}
                                            // value={formData.travelers}
                                            onChange={(value) => handleChange('travelers', value)}
                                            options={[
                                                { value: '01', label: '01' },
                                                { value: '02', label: '02' },
                                                { value: '03', label: '03' },
                                                { value: '04', label: '04' },
                                                { value: '05', label: '05' },
                                                { value: '06', label: '06' },
                                                { value: '07', label: '07' },
                                                { value: '08', label: '08' },
                                                { value: '09', label: '09' },
                                                { value: '10', label: '10' },
                                                { value: '11', label: '11' },
                                                { value: '12', label: '12' },
                                                { value: '13', label: '13' },
                                                { value: '14', label: '14' },
                                                { value: '15', label: '15' },
                                                { value: '16', label: '16' },
                                                { value: '17', label: '17' },
                                                { value: '18', label: '18' },
                                                { value: '19', label: '19' },
                                                { value: '20', label: '20' },
                                            ]}
                                        />
                                        {agencyErrors.travelers && <p className="error">* {agencyErrors.travelers}</p>}
                                    </div>
                                </div>
                                {
                                    formData.travelers >= 1 &&
                                    <>
                                        <h1 className='details_title'>Travellers Details</h1>
                                        <div id='travellers-details' className='p-2 user-details'>
                                            <div className='w-100'>
                                                <div className='row m-0 form-heading d-none d-md-flex'>
                                                    <div className='col-md-1 p-0'>
                                                        <div className='form-input'>
                                                            <label>S.No:</label>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-5 p-0'>
                                                        <div className='form-input'>
                                                            <label>Name:</label>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3 p-0'>
                                                        <div className='form-input'>
                                                            <label>Gender:</label>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-3 p-0'>
                                                        <div className='form-input'>
                                                            <label>Age:</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {travelerRows}
                                            </div>
                                        </div>
                                    </>
                                }

                            </div>
                            <div className='col-xl-4 p-4 pt-2' id='booking-container'>
                                <div id='booking-section'>
                                    <div>
                                        <h1 className='details_title text-center'>Booking Details</h1>
                                        <div className='p-1 form-date row m-0'>
                                            <div className="col-7 p-0"><p className='m-0 preview-text'>Sea Walk Date:</p></div>
                                            <div className="col-5 p-0"><p className='m-0 preview-text'>{formData.date && new Date(formData.date).toLocaleDateString()}</p></div>
                                        </div>
                                        <div className='p-1 form-slot row m-0'>
                                            <div className="col-7 p-0"><p className='m-0 preview-text'>Slot (Sea Walk Time):</p></div>
                                            <div className="col-5 p-0"><p className='m-0 preview-text'>{formData.slot}</p></div>
                                        </div>
                                        <div className='p-1 form-travellers row m-0'>
                                            <div className="col-7 p-0"><p className='m-0 preview-text'>No.of Travellers:</p></div>
                                            <div className="col-5 p-0"><p className='m-0 preview-text'>{formData.travelers} person</p></div>
                                        </div>
                                        <div className='p-1 row m-0'>
                                            <div className="col-7 p-0"><p className='m-0 preview-text'>Actual Amount ({formData.travelers} x 3100):</p></div>
                                            <div className="col-5 p-0"><p className='m-0 preview-text'>₹ {formData.travelers * 3100}</p></div>
                                        </div>
                                        <div className='p-1 row m-0'>
                                            <div className="col-7 p-0"><p className='m-0 preview-text gst-text'>GST (5%):</p></div>
                                            <div className="col-5 p-0"><p className='m-0 preview-text gst-text'>₹ {Math.round((formData?.travelers * 3100) * 5 / (100 + 5))}</p></div>
                                        </div>
                                        <div className='row m-1 amount-to-pay'>
                                            <div className="col-7 p-0 m-0"><p className='m-0 preview-amt'>Amount To Pay</p></div>
                                            <div className="col-5 p-0 m-0"><p className='m-0 preview-amt'>₹ {formData.travelers * 3100}</p></div>
                                        </div>
                                        <div className='p-1 p-0'>
                                            <Button name="Pay Now" type="submit" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <nav className="fixed-bottom bottom-button">
                        <div className='form-button h-100'>
                            <button className='submit-button' type='submit'>
                                <p className='btn-text'>{formData.travelers ? `₹ ${formData.travelers * 3100}/-` : ""}</p>
                                <p className='btn-text'>Pay Now <img style={{ width: "35px", marginLeft: "10px" }} src='/images/right-arrow.png'></img></p>
                            </button>
                        </div>
                    </nav>
                </form>
            </div>
        </Fragment>
    )
}

AgencyBooking.prototype = {
    makeBooking: PropTypes.func.isRequired,
    setFormdata: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
}


const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps, { setFormdata, makeBooking, updateUserProfile })(AgencyBooking)