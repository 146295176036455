import { ArrowLeftOutlined } from '@ant-design/icons';
import { FloatButton } from 'antd';
import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

const RefundPolicy = () => {
  const navigate = useNavigate()
  return (
    <Fragment>
      <div>
        <FloatButton type='primary' shape="circle" size="large" style={{ top: 30, left: 30 }} icon={<ArrowLeftOutlined />} onClick={() => navigate('/agency-login')}></FloatButton>
      </div>
      <div className='p-3 p-lg-5'>
        <section id='about-section'>
          <h1 className='heading text-center mb-4'>Refund Policy</h1>
          <p className='text-center'>Cancellation & Refund Policy: Reschedule might be subject to availability. The full payment confirms your reservation, with details provided in the confirmation email. Note: The initial deposit is non-refundable, and no-shows or cancellations before 24 hours of Sea Walk Time get deducts Rs.1000/ as a cancellation fee. We encourage rescheduling, possible up to 5 days before the original booking date. For assistance, contact our customer service team.</p>
        </section>
      </div>
    </Fragment>
  )
}

export default RefundPolicy