import { LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT, USER_LOADED, AUTH_ERROR, PROFILE_UPDATED, PROFILE_UPDATED_FAIL } from "./types";
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import setAuthToken from '../utils/setAuthToken';
import { setAlert } from "./alert";

// load user 
export const loadUser = () => async (dispatch) => {
    if (localStorage.adminToken) {
        setAuthToken(localStorage.adminToken);
    }
    console.log(localStorage.adminToken)
    const API = axios.create({
        baseURL: "https://agent.seagoldseawalk.com",
    });

    try {
        const res = await API.get("https://www.seagoldseawalk.com/user_webservice/Login/loadAgent").then((res) => {
            console.log(res)
            if (res?.data) {
                dispatch({
                    type: USER_LOADED,
                    payload: res?.data
                })
                console.log("user loaded");
            }
        });
    } catch (err) {

        if (!err?.response) {
            console.log("no server response");
        }

        if (err.response.data.error_code == 0) {
            dispatch({
                type: AUTH_ERROR,
            })
            console.log("error", err.response.data.msg); // set alert msg
        }
    }
}

// login user
export const login = ({ email, password }) => async (dispatch) => {

    const API = axios.create({
        baseURL: "https://agent.seagoldseawalk.com",
    });

    try {
        const res = await API.post("https://www.seagoldseawalk.com/user_webservice/Login/agentLogin", {
            email, password
        }).then((res) => {
            if (res?.data.token) {
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: res?.data
                })
                dispatch(loadUser())
                dispatch(setAlert("Welcome, you are Logged in Successfully!", "success"))
                console.log("Logged successfully");
            }
        });
    } catch (err) {

        if (!err?.response) {
            console.log("no server response");
        }

        if (err.response.data.error_code == 0) {
            dispatch({
                type: LOGIN_FAIL,
            })
            err.response.data.msg && dispatch(setAlert(err.response.data.msg, "error"))
            console.log("login failed", err.response.data.msg); // set alert msg
        }
    }

}

// logout / clear profile
export const logout = () => (dispatch) => {
    dispatch({ type: LOGOUT })
    dispatch(setAlert("Logout Done!", "success"))
}

// update user profile
export const updateUserProfile = ({ name, agencyGSTnumber, city, state, country }) => async (dispatch) => {
    const API = axios.create({
        baseURL: "https://agent.seagoldseawalk.com",
    });

    try {
        console.log(name)
        const res = await API.post("https://www.seagoldseawalk.com/user_webservice/Login/updateUser", {
            name, agencyGSTnumber, city, state, country
        }).then((res) => {
            console.log(res.data)
            if (res?.data.error_code == 1) {
                dispatch({
                    type: PROFILE_UPDATED,
                })
                dispatch(loadUser())
            }
        });
    } catch (err) {

        if (!err?.response) {
            console.log("no server response");
        }

        if (err.response.data.error_code == 0) {
            dispatch({
                type: PROFILE_UPDATED_FAIL,
            })
            console.log("Profile updated failed", err.response.data.msg); // set alert msg
        }
    }

}