import React, { useState, useEffect } from 'react';
import "./SideNav.css";
import { Link, NavLink } from 'react-router-dom';

export const SideNav = () => {

  const [activeMenu, setActiveMenu] = useState(null);

  const onClickMenu = (menuId) => {
    setActiveMenu(menuId);
  }

  return (
    <div className='side-nav d-none d-lg-flex flex-column justify-content-between'>
      <div>
        <div className='d-flex justify-content-center align-items-center'>
          <a href="#" >
            <img className="logo" src='/images/sea-gold-logo.png' alt="logo"></img>
          </a>
        </div>
        <ul>
          <li><NavLink className={activeMenu === 'Dashboard' ? 'menu-link active' : 'menu-link'} to='/agency-dashboard' onClick={() => onClickMenu('Dashboard')}>Dashboard</NavLink></li>
          {/* <hr></hr> */}
          <div className='menu-group'>
            <h6>Travel Agency</h6>
            <li><NavLink className={activeMenu === 'agency-booking-details' ? 'menu-link active' : 'menu-link'} to='/agency-booking-details' onClick={() => onClickMenu('agency-booking-detailst')}>Booking Details</NavLink></li>
            <li><NavLink className={activeMenu === '' ? 'menu-link active' : 'menu-link'} to='/' onClick={() => onClickMenu('')}>Book Sea Walk</NavLink></li>
          </div>
        </ul>
      </div>
      <div className='bottom-menu'>
        <li className="mb-1"><NavLink to='/agent-terms-and-conditions' onClick={() => onClickMenu('')}>Terms & Conditions</NavLink></li>
        <li className="mb-1"><NavLink to='/agent-privacy-policy' onClick={() => onClickMenu('')}>Privacy Policy</NavLink></li>
        <li className="mb-1"><NavLink to='/agent-refund-policy' onClick={() => onClickMenu('')}>Refund Policy</NavLink></li>
      </div>
    </div>
  )
}
