import { BOOKING_SUCCESS, BOOKING_FAIL, GET_BOOKING_HISTORY, BOOKING_HISTORY_ERROR, GET_BOOKING_DETAILS_ERROR, GET_BOOKING_DETAILS } from "../actions/types";

const initialState = {
    bookingDetails: null,
    bookingHistory: [],
    loading: true,
    error: {}
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case BOOKING_SUCCESS:
        case GET_BOOKING_DETAILS:
            return {
                ...state,
                bookingDetails: payload,
                loading: false,
            }
        case BOOKING_FAIL:
        case BOOKING_HISTORY_ERROR:
        case GET_BOOKING_DETAILS_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            }
        case GET_BOOKING_HISTORY:
            return {
                ...state,
                bookingHistory: payload,
                loading: false
            }
        default:
            return state;
    }
}