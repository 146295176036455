import { Header } from "./components/Header";
import { SideNav } from "./components/SideNav";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Dashboard from "./pages/Dashboard";
import AgencyDetails from "./pages/AgencyDetails";
import Login from "./pages/Login";
import { Provider } from "react-redux";
import store from "./store";
import { useEffect } from "react";
import { loadUser } from "./actions/auth";
import PrivateRoute from "./components/routing/PrivateRoute";
import AgencyHome from "./pages/AgencyHome";
import AgencyBooking from './pages/Booking';
import ThankYou from "./pages/ThankYou";
import AgencyProfile from "./pages/AgencyProfile";
import Alert from "./components/layout/Alert";
import setAuthToken from "./utils/setAuthToken";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsConditions from "./pages/TermsConditions";
import AgentTermsConditions from "./pages/AgentTermsConditions";
import AgentPrivacyPolicy from "./pages/AgentPrivacyPolicy";
import RefundPolicy from "./pages/RefundPolicy";
import AgentRefundPolicy from "./pages/AgentRefundPolicy";

if (localStorage.adminToken) {
  setAuthToken(localStorage.adminToken);
}

function App() {

  useEffect(() => {
    store.dispatch(loadUser())
  }, [])

  return (
    <Provider store={store}>
      <div className="App h-100">
        <BrowserRouter>
          <section className="h-100">
            <Alert />
            <Routes>
              <Route path="/" element={<PrivateRoute component={AgencyHome} />} />
              <Route path="/agency-dashboard" element={<PrivateRoute component={Dashboard} />} />
              <Route path="/agency-booking/:agencyBooking" element={<PrivateRoute component={AgencyBooking} />} />
              <Route path="/agency-booking-details" element={<PrivateRoute component={AgencyDetails} />} />
              <Route path="/agency-profile" element={<PrivateRoute component={AgencyProfile} />} />
              <Route path="/agency-login" element={<Login />} />
              <Route path="/agent-booking-status/:thankyou" element={<PrivateRoute component={ThankYou} />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/refund-policy" element={<RefundPolicy />} />
              <Route path="/terms-and-conditions" element={<TermsConditions />} />
              <Route path="/agent-terms-and-conditions" element={<PrivateRoute component={AgentTermsConditions} />} />
              <Route path="/agent-privacy-policy" element={<PrivateRoute component={AgentPrivacyPolicy} />} />
              <Route path="/agent-refund-policy" element={<PrivateRoute component={AgentRefundPolicy} />} />
              {/* <Route path="*" element={<Login />} /> */}
            </Routes>
          </section>
        </BrowserRouter>
      </div>
    </Provider>

  );
}

export default App;
