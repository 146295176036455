import { SET_FORM_DATA, SET_BOOKING_HISTORY } from "./types";

export const setFormdata = (formdata) => (dispatch) => {
    dispatch({
        type: SET_FORM_DATA,
        payload: formdata
    })
}

export const setBookingHistory = (formdata) => (dispatch) => {
    dispatch({
        type: SET_BOOKING_HISTORY,
        payload: formdata
    })
}