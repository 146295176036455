import { Input, InputNumber, message } from 'antd';
import { PropTypes } from 'prop-types';
import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { updateUserProfile } from '../actions/auth';
import "./Profile.css";
import Header from '../components/Header';
import { SideNav } from '../components/SideNav';

const AgencyProfile = ({ auth: { user, isAuthenticated }, updateUserProfile }) => {

    const loggedUser = isAuthenticated ? user : {};

    const [updateProfileDetails, setUpdateProfileDetails] = useState({
        id: loggedUser.id,
        name: loggedUser.agent_name,
        email: loggedUser.email,
        phoneNumber: loggedUser.phone_number,
        agencyName: loggedUser.agent_company_name,
        agencyGSTnumber: loggedUser.gst_number ? loggedUser.gst_number : "",
        city: loggedUser.city ? loggedUser.city : "",
        state: loggedUser.state ? loggedUser.state : "",
        country: loggedUser.country ? loggedUser.country : ""
    });

    const [errors, setErrors] = useState({
        name: "",
        email: "",
        phoneNumber: "",
        agencyName: "",
        agencyGSTnumber:"",
        city: "",
        mismatchPassword: "",
    });


    // handler for user inputs
    const handleUserInputChange = (event) => {
        setUpdateProfileDetails({ ...updateProfileDetails, [event.target.name]: event.target.value, });
    };

    // handler for user phone number input
    const handleUserPhoneNumberInput = (value) => {
        setUpdateProfileDetails({ ...updateProfileDetails, phoneNumber: value, });
    }


    // handle onsubmit 
    const onSubmit = (e) => {
        e.preventDefault();

        let newErrors = {};

        if (!updateProfileDetails.name) {
            newErrors.name = "Name is required";
        }
        if (!updateProfileDetails.email) {
            newErrors.email = "Email ID is required";
        }
        if (!updateProfileDetails.phoneNumber) {
            newErrors.phoneNumber = "Phone Number is required";
        }
        if (!updateProfileDetails.agencyName) {
            newErrors.agencyName = "Agency Name is required";
        }
        if (!updateProfileDetails.agencyGSTnumber) {
            newErrors.agencyGSTnumber = "Agency GST Number is required";
        }
        if (!updateProfileDetails.city) {
            newErrors.city = "City is required";
        }

        // Set all the errors at once
        setErrors(newErrors);

        const areErrorsEmpty = Object.values(newErrors).every((error) => !error);

        if (areErrorsEmpty) {
            const { name, email, phoneNumber, agencyName, agencyGSTnumber, city, state, country } = updateProfileDetails;
            updateUserProfile({ name, agencyGSTnumber, city, state, country })
        } else {
            // Set all the errors at once
            setErrors(newErrors);
        }
    }

    return (
        <Fragment>
            <Header />
            <SideNav />
            <div className='app-content'>
                <div className='update-profile'>
                    <h1 className='heading text-center'>Update Your Profile</h1>
                    <p className='text-center'>Welcome to your profile section of Sea Gold Sea Walk. Here, you can view & edit your profile. </p>
                    <br></br>
                    <form onSubmit={(e) => onSubmit(e)} className='profile-form'>
                        <div className='row m-0'>
                            <div className='col-md-6'>
                                <div className='form-input p-1'>
                                    <label>Name: <span style={{ color: "red" }}>*</span></label>
                                    <Input name='name' placeholder="Name" autoComplete="none" type='text' value={updateProfileDetails.name} onChange={(e) => handleUserInputChange(e)} />
                                    {errors.name && <p className="error">* {errors.name}</p>}
                                </div>
                                <div className='form-input p-1'>
                                    <label>Email ID: <span style={{ color: "red" }}>*</span></label>
                                    <Input name='email' placeholder="Email Id" autoComplete="none" type='email' disabled value={updateProfileDetails.email} onChange={(e) => handleUserInputChange(e)} />
                                    {errors.email && <p className="error">* {errors.email}</p>}
                                </div>
                                <div className='form-input p-1'>
                                    <label>Phone Number: <span style={{ color: "red" }}>*</span></label>
                                    <InputNumber name='phoneNumber' min={1} style={{ width: "100%" }} type='number' disabled upHandler={null} downHandler={null} placeholder="Phone Number" autoComplete="none" value={updateProfileDetails.phoneNumber} onChange={(value) => handleUserPhoneNumberInput(value)} />
                                    {errors.phoneNumber && <p className="error">* {errors.phoneNumber}</p>}
                                </div>
                                <div className='form-input p-1'>
                                    <label>Agency Name: <span style={{ color: "red" }}>*</span></label>
                                    <Input name='agencyName' placeholder="Agency Name" autoComplete="none" type='text' disabled value={updateProfileDetails.agencyName} onChange={(e) => handleUserInputChange(e)} />
                                    {errors.agencyName && <p className="error">* {errors.agencyName}</p>}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='form-input p-1'>
                                    <label>Agency GST Number: <span style={{ color: "red" }}>*</span></label>
                                    <Input name='agencyGSTnumber' style={{ width: "100%" }} placeholder="Agency GST Number" autoComplete="none" pattern='[0-9]{2}[A-Z]{3}[ABCFGHLJPTF]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}' value={updateProfileDetails.agencyGSTnumber} onChange={(e) => handleUserInputChange(e)} />
                                    {errors.agencyGSTnumber && <p className="error">* {errors.agencyGSTnumber}</p>}
                                </div>
                                <div className='form-input p-1'>
                                    <label>City: <span style={{ color: "red" }}>*</span></label>
                                    <Input name='city' placeholder="City" autoComplete="none" type='text' value={updateProfileDetails.city} onChange={(e) => handleUserInputChange(e)} />
                                </div>
                                <div className='form-input p-1'>
                                    <label>State:</label>
                                    <Input name='state' placeholder="State" autoComplete="none" type='text' value={updateProfileDetails.state} onChange={(e) => handleUserInputChange(e)} />
                                </div>
                                <div className='form-input p-1'>
                                    <label>Country:</label>
                                    <Input name='country' placeholder="Country" autoComplete="none" type='text' value={updateProfileDetails.country} onChange={(e) => handleUserInputChange(e)} />
                                </div>
                                <br></br>
                            </div>

                            <div className='form-input d-flex justify-content-center align-items-center'>
                                <button className='form-btn w-auto'>Save Changes</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    )
}

AgencyProfile.prototype = {
    updateUserProfile: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    auth: state.auth
})

export default connect(mapStateToProps, { updateUserProfile })(AgencyProfile);